.snackbar-card {
  box-shadow: 0px 2px 25px 0px #0000002b;
  background: var(--white);
  color: var(--black);
  border-radius: 3px;
  position: fixed;
  display: flex;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  gap: 15px;
}

.snackbar-content {
  text-align: center;
  font-size: 18px;
}

.snackbar-left {
  left: 5% !important;
}

.snackbar-right {
  right: 5% !important;
}

.snackbar-top {
  top: 5% !important;
}

.snackbar-bottom {
  bottom: 5% !important;
}

.snackbar-success-icon,
.snackbar-failure-icon,
.snackbar-info-icon {
  color: white;
  font-size: 18px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-success-icon {
  background-color: #218721;
}
.snackbar-failure-icon {
  background-color: #bd1919;
}
.snackbar-info-icon {
  background-color: #a87900;
  padding-top: 2px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
