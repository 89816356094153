:root {
  /* Css Variables will go here  */
  /* Kivuto main colors (should include 90%) */
  --orange: #EC6C2E;
  --light-blue: #1B9EDD;
  --light-green: #87C7B8;
  --medium-grey: #6F6E6F;
  --navy-blue: #333F50;
  /* kivuto secondary colors (should be include 10%) */
  --light-orange: #F9B892;
  --teal: #0EAFBA;
  --brown: #A26435;
  --light-grey: #C4C4C3;
  --dark-grey: #282828;
  /* Common set */
  --primary-color: #11689C;
  --yellow: #ffe065;
  --white: #fff;
  --black: #000;
  --grey1: #F2F2F2;
  --grey2: #C6C6C6;
  --grey3: #9E9E9E;
  --grey4: #878787;
  --grey5: #707070;
  --grey6: #525252;
  --grey7: #3D3D3D;
  --grey8: #232323;
  --grey9: #4e4e4e;
  --khaki: #BDB898;
  --red: #BD1919;
  --border-grey: #EBEBEB;
  scrollbar-color: var(--primary-color) transparent !important;
  scrollbar-width: thin !important;
}
/* Css Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


/* FONTS */
@font-face {
  font-family: 'Avenir-light';
  src: local('Avenir-light'), url(./fonts/avenir/AvenirLTStd-Light.woff) format('woff'),
                              url(./fonts/avenir/AvenirLTStd-Light.woff2) format('woff2')
                              url(./fonts/avenir/AvenirLTStd-Light.eot?#iefix) format('embedded-opentype')
                              url(./fonts/avenir/AvenirLTStd-Light.eot) format('embedded-opentype'),
                              url(./fonts/avenir/AvenirLTStd-Light.ttf) format('opentype'),
                              url(./fonts/avenir/AvenirLTStd-Light.otf) format('truetype'),
}

@font-face {
  font-family: 'Avenir-roman';
  src: local('Avenir-roman'), url(./fonts/avenir/AvenirLTStd-Roman.woff) format('woff'),
                              url(./fonts/avenir/AvenirLTStd-Roman.woff2) format('woff2')
                              url(./fonts/avenir/AvenirLTStd-Roman.eot) format('embedded-opentype'),
                              url(./fonts/avenir/AvenirLTStd-Roman.eot?#iefix) format('embedded-opentype'),
                              url(./fonts/avenir/AvenirLTStd-Roman.ttf) format('opentype'),
                              url(./fonts/avenir/AvenirLTStd-Roman.otf) format('truetype')
}

@font-face {
  font-family: 'Avenir-heavy';
  src: local('Avenir-heavy'), url(./fonts/avenir/AvenirLTStd-Heavy.woff) format('woff'),
                              url(./fonts/avenir/AvenirLTStd-Heavy.woff2) format('woff2')
                              url(./fonts/avenir/AvenirLTStd-Heavy.eot) format('embedded-opentype'),
                              url(./fonts/avenir/AvenirLTStd-Heavy.eot?#iefix) format('embedded-opentype'),
                              url(./fonts/avenir/AvenirLTStd-Heavy.ttf) format('opentype'),
                              url(./fonts/avenir/AvenirLTStd-Heavy.otf) format('truetype'),
}

/* content */
html * {
  scroll-behavior: smooth
}
body {
  font-family: Avenir-light, Arial, sans-serif;
	line-height: 1;
  overflow-x: hidden;
  overflow-y: overlay;

}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:is(a,button):hover {
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 5px;
  position:absolute;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  outline: 1px solid slategrey;
}

select {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.light {
  font-family: Avenir-light, Arial, sans-serif;
}
.heavy {
  font-family: Avenir-heavy, Arial, sans-serif;
}

.roman {
  font-family: Avenir-roman, Arial, sans-serif;
}
.btn-wrapper {
  border: none;
  background: none;
  color: inherit;
}

.button-primary{
  border: none;
  background-color: var(--primary-color);
  color: #fff;
}

.button-primary:disabled{
  border: none;
  background-color: var(--navy-blue);
  color: rgba(245, 239, 239,.8);
  cursor: unset
}
/* Animations */
.fade-in {
	-webkit-animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	animation: slide-in-blurred-top 0.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.wait {
  cursor: wait !important;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.section-title {
  font-size: 36px;
  text-align: left;
  margin: 0 5% 90px 5%;
  font-family: Avenir-heavy, Arial, sans-serif;
}

.blue-scroll-bar {
  scrollbar-color: var(--primary-color) transparent !important;
  scrollbar-width: thin !important;
}

.blue-scroll-bar::-webkit-scrollbar {
  width: 5px;
  position:absolute;
}

.blue-scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.blue-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  outline: 1px solid slategrey;
}

.banner-subtitle {
  font-size: 17px !important;
}

.for-screen-reader {
  opacity: 0;
  position: absolute;
  left: -9999px;
}