@charset "UTF-8";
/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
.remote-content .* Copyright 2011-2019 Twitter,
 Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.remote-content * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.remote-content *:before,
.remote-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.remote-content input,
.remote-content button,
.remote-content select,
.remote-content textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.remote-content figure {
  margin: 0;
}

.remote-content img {
  vertical-align: middle;
}

.remote-content .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.remote-content .img-rounded {
  border-radius: 6px;
}

.remote-content .img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.remote-content .img-circle {
  border-radius: 50%;
}

.remote-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.remote-content .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.remote-content .sr-only-focusable:active,
.remote-content .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.remote-content [role='button'] {
  cursor: pointer;
}

.remote-content small,
.remote-content .small {
  font-size: 85%;
}

.remote-content mark,
.remote-content .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.remote-content .text-left {
  text-align: left;
}

.remote-content .text-right {
  text-align: right;
}

.remote-content .text-center {
  text-align: center;
}
.remote-content .text-center h2 {
  font-size: 26px;
  line-height: 1;
  font-weight: 500;
}

.remote-content .text-justify {
  text-align: justify;
}

.remote-content .text-nowrap {
  white-space: nowrap;
}

.remote-content .text-lowercase {
  text-transform: lowercase;
}

.remote-content .text-uppercase,
.remote-content .initialism {
  text-transform: uppercase;
}

.remote-content .text-capitalize {
  text-transform: capitalize;
}

.remote-content .text-muted {
  color: #777777;
}

.remote-content .text-primary {
  color: #337ab7;
}

.remote-content .bg-primary {
  color: #fff;
}

.remote-content .bg-primary {
  background-color: #337ab7;
}

.remote-content .bg-success {
  background-color: #dff0d8;
}

.remote-content .bg-info {
  background-color: #d9edf7;
}

.remote-content .bg-warning {
  background-color: #fcf8e3;
}

.remote-content .bg-danger {
  background-color: #f2dede;
}

.remote-content .page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

.remote-content .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.remote-content .list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.remote-content .list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.remote-content .dl-horizontal dd:before,
.remote-content .dl-horizontal dd:after {
  display: table;
  content: ' ';
}
.remote-content .dl-horizontal dd:after {
  clear: both;
}
@media (min-width: 768px) {
  .remote-content .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .remote-content .dl-horizontal dd {
    margin-left: 180px;
  }
}

.remote-content .initialism {
  font-size: 90%;
}
.remote-content .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.remote-content .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.remote-content .container:before,
.remote-content .container:after {
  display: table;
  content: ' ';
}
.remote-content .container:after {
  clear: both;
}
@media (min-width: 768px) {
  .remote-content .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .remote-content .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .remote-content .container {
    width: 1170px;
  }
}

.remote-content .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.remote-content .container-fluid:before,
.remote-content .container-fluid:after {
  display: table;
  content: ' ';
}
.remote-content .container-fluid:after {
  clear: both;
}

.remote-content .row {
  margin-right: -15px;
  margin-left: -15px;
}
.remote-content .row:before,
.remote-content .row:after {
  display: table;
  content: ' ';
}
.remote-content .row:after {
  clear: both;
}

.remote-content .row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.remote-content .row-no-gutters [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.remote-content .col-xs-1,
.remote-content .col-sm-1,
.remote-content .col-md-1,
.remote-content .col-lg-1,
.remote-content .col-xs-2,
.remote-content .col-sm-2,
.remote-content .col-md-2,
.remote-content .col-lg-2,
.remote-content .col-xs-3,
.remote-content .col-sm-3,
.remote-content .col-md-3,
.remote-content .col-lg-3,
.remote-content .col-xs-4,
.remote-content .col-sm-4,
.remote-content .col-md-4,
.remote-content .col-lg-4,
.remote-content .col-xs-5,
.remote-content .col-sm-5,
.remote-content .col-md-5,
.remote-content .col-lg-5,
.remote-content .col-xs-6,
.remote-content .col-sm-6,
.remote-content .col-md-6,
.remote-content .col-lg-6,
.remote-content .col-xs-7,
.remote-content .col-sm-7,
.remote-content .col-md-7,
.remote-content .col-lg-7,
.remote-content .col-xs-8,
.remote-content .col-sm-8,
.remote-content .col-md-8,
.remote-content .col-lg-8,
.remote-content .col-xs-9,
.remote-content .col-sm-9,
.remote-content .col-md-9,
.remote-content .col-lg-9,
.remote-content .col-xs-10,
.remote-content .col-sm-10,
.remote-content .col-md-10,
.remote-content .col-lg-10,
.remote-content .col-xs-11,
.remote-content .col-sm-11,
.remote-content .col-md-11,
.remote-content .col-lg-11,
.remote-content .col-xs-12,
.remote-content .col-sm-12,
.remote-content .col-md-12,
.remote-content .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.remote-content .col-xs-1,
.remote-content .col-xs-2,
.remote-content .col-xs-3,
.remote-content .col-xs-4,
.remote-content .col-xs-5,
.remote-content .col-xs-6,
.remote-content .col-xs-7,
.remote-content .col-xs-8,
.remote-content .col-xs-9,
.remote-content .col-xs-10,
.remote-content .col-xs-11,
.remote-content .col-xs-12 {
  float: left;
}

.remote-content .col-xs-1 {
  width: 8.3333333333%;
}

.remote-content .col-xs-2 {
  width: 16.6666666667%;
}

.remote-content .col-xs-3 {
  width: 25%;
}

.remote-content .col-xs-4 {
  width: 33.3333333333%;
}

.remote-content .col-xs-5 {
  width: 41.6666666667%;
}

.remote-content .col-xs-6 {
  width: 50%;
}

.remote-content .col-xs-7 {
  width: 58.3333333333%;
}

.remote-content .col-xs-8 {
  width: 66.6666666667%;
}

.remote-content .col-xs-9 {
  width: 75%;
}

.remote-content .col-xs-10 {
  width: 83.3333333333%;
}

.remote-content .col-xs-11 {
  width: 91.6666666667%;
}

.remote-content .col-xs-12 {
  width: 100%;
}

.remote-content .col-xs-pull-0 {
  right: auto;
}

.remote-content .col-xs-pull-1 {
  right: 8.3333333333%;
}

.remote-content .col-xs-pull-2 {
  right: 16.6666666667%;
}

.remote-content .col-xs-pull-3 {
  right: 25%;
}

.remote-content .col-xs-pull-4 {
  right: 33.3333333333%;
}

.remote-content .col-xs-pull-5 {
  right: 41.6666666667%;
}

.remote-content .col-xs-pull-6 {
  right: 50%;
}

.remote-content .col-xs-pull-7 {
  right: 58.3333333333%;
}

.remote-content .col-xs-pull-8 {
  right: 66.6666666667%;
}

.remote-content .col-xs-pull-9 {
  right: 75%;
}

.remote-content .col-xs-pull-10 {
  right: 83.3333333333%;
}

.remote-content .col-xs-pull-11 {
  right: 91.6666666667%;
}

.remote-content .col-xs-pull-12 {
  right: 100%;
}

.remote-content .col-xs-push-0 {
  left: auto;
}

.remote-content .col-xs-push-1 {
  left: 8.3333333333%;
}

.remote-content .col-xs-push-2 {
  left: 16.6666666667%;
}

.remote-content .col-xs-push-3 {
  left: 25%;
}

.remote-content .col-xs-push-4 {
  left: 33.3333333333%;
}

.remote-content .col-xs-push-5 {
  left: 41.6666666667%;
}

.remote-content .col-xs-push-6 {
  left: 50%;
}

.remote-content .col-xs-push-7 {
  left: 58.3333333333%;
}

.remote-content .col-xs-push-8 {
  left: 66.6666666667%;
}

.remote-content .col-xs-push-9 {
  left: 75%;
}

.remote-content .col-xs-push-10 {
  left: 83.3333333333%;
}

.remote-content .col-xs-push-11 {
  left: 91.6666666667%;
}

.remote-content .col-xs-push-12 {
  left: 100%;
}

.remote-content .col-xs-offset-0 {
  margin-left: 0%;
}

.remote-content .col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.remote-content .col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.remote-content .col-xs-offset-3 {
  margin-left: 25%;
}

.remote-content .col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.remote-content .col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.remote-content .col-xs-offset-6 {
  margin-left: 50%;
}

.remote-content .col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.remote-content .col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.remote-content .col-xs-offset-9 {
  margin-left: 75%;
}

.remote-content .col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.remote-content .col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.remote-content .col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .remote-content .col-sm-1,
  .remote-content .col-sm-2,
  .remote-content .col-sm-3,
  .remote-content .col-sm-4,
  .remote-content .col-sm-5,
  .remote-content .col-sm-6,
  .remote-content .col-sm-7,
  .remote-content .col-sm-8,
  .remote-content .col-sm-9,
  .remote-content .col-sm-10,
  .remote-content .col-sm-11,
  .remote-content .col-sm-12 {
    float: left;
  }

  .remote-content .col-sm-1 {
    width: 8.3333333333%;
  }

  .remote-content .col-sm-2 {
    width: 16.6666666667%;
  }

  .remote-content .col-sm-3 {
    width: 25%;
  }

  .remote-content .col-sm-4 {
    width: 33.3333333333%;
  }

  .remote-content .col-sm-5 {
    width: 41.6666666667%;
  }

  .remote-content .col-sm-6 {
    width: 50%;
  }

  .remote-content .col-sm-7 {
    width: 58.3333333333%;
  }

  .remote-content .col-sm-8 {
    width: 66.6666666667%;
  }

  .remote-content .col-sm-9 {
    width: 75%;
  }

  .remote-content .col-sm-10 {
    width: 83.3333333333%;
  }

  .remote-content .col-sm-11 {
    width: 91.6666666667%;
  }

  .remote-content .col-sm-12 {
    width: 100%;
  }

  .remote-content .col-sm-pull-0 {
    right: auto;
  }

  .remote-content .col-sm-pull-1 {
    right: 8.3333333333%;
  }

  .remote-content .col-sm-pull-2 {
    right: 16.6666666667%;
  }

  .remote-content .col-sm-pull-3 {
    right: 25%;
  }

  .remote-content .col-sm-pull-4 {
    right: 33.3333333333%;
  }

  .remote-content .col-sm-pull-5 {
    right: 41.6666666667%;
  }

  .remote-content .col-sm-pull-6 {
    right: 50%;
  }

  .remote-content .col-sm-pull-7 {
    right: 58.3333333333%;
  }

  .remote-content .col-sm-pull-8 {
    right: 66.6666666667%;
  }

  .remote-content .col-sm-pull-9 {
    right: 75%;
  }

  .remote-content .col-sm-pull-10 {
    right: 83.3333333333%;
  }

  .remote-content .col-sm-pull-11 {
    right: 91.6666666667%;
  }

  .remote-content .col-sm-pull-12 {
    right: 100%;
  }

  .remote-content .col-sm-push-0 {
    left: auto;
  }

  .remote-content .col-sm-push-1 {
    left: 8.3333333333%;
  }

  .remote-content .col-sm-push-2 {
    left: 16.6666666667%;
  }

  .remote-content .col-sm-push-3 {
    left: 25%;
  }

  .remote-content .col-sm-push-4 {
    left: 33.3333333333%;
  }

  .remote-content .col-sm-push-5 {
    left: 41.6666666667%;
  }

  .remote-content .col-sm-push-6 {
    left: 50%;
  }

  .remote-content .col-sm-push-7 {
    left: 58.3333333333%;
  }

  .remote-content .col-sm-push-8 {
    left: 66.6666666667%;
  }

  .remote-content .col-sm-push-9 {
    left: 75%;
  }

  .remote-content .col-sm-push-10 {
    left: 83.3333333333%;
  }

  .remote-content .col-sm-push-11 {
    left: 91.6666666667%;
  }

  .remote-content .col-sm-push-12 {
    left: 100%;
  }

  .remote-content .col-sm-offset-0 {
    margin-left: 0%;
  }

  .remote-content .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }

  .remote-content .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }

  .remote-content .col-sm-offset-3 {
    margin-left: 25%;
  }

  .remote-content .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }

  .remote-content .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }

  .remote-content .col-sm-offset-6 {
    margin-left: 50%;
  }

  .remote-content .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }

  .remote-content .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }

  .remote-content .col-sm-offset-9 {
    margin-left: 75%;
  }

  .remote-content .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }

  .remote-content .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }

  .remote-content .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .remote-content .col-md-1,
  .remote-content .col-md-2,
  .remote-content .col-md-3,
  .remote-content .col-md-4,
  .remote-content .col-md-5,
  .remote-content .col-md-6,
  .remote-content .col-md-7,
  .remote-content .col-md-8,
  .remote-content .col-md-9,
  .remote-content .col-md-10,
  .remote-content .col-md-11,
  .remote-content .col-md-12 {
    float: left;
  }

  .remote-content .col-md-1 {
    width: 8.3333333333%;
  }

  .remote-content .col-md-2 {
    width: 16.6666666667%;
  }

  .remote-content .col-md-3 {
    width: 25%;
  }

  .remote-content .col-md-4 {
    width: 33.3333333333%;
  }

  .remote-content .col-md-5 {
    width: 41.6666666667%;
  }

  .remote-content .col-md-6 {
    width: 50%;
  }

  .remote-content .col-md-7 {
    width: 58.3333333333%;
  }

  .remote-content .col-md-8 {
    width: 66.6666666667%;
  }

  .remote-content .col-md-9 {
    width: 75%;
  }

  .remote-content .col-md-10 {
    width: 83.3333333333%;
  }

  .remote-content .col-md-11 {
    width: 91.6666666667%;
  }

  .remote-content .col-md-12 {
    width: 100%;
  }

  .remote-content .col-md-pull-0 {
    right: auto;
  }

  .remote-content .col-md-pull-1 {
    right: 8.3333333333%;
  }

  .remote-content .col-md-pull-2 {
    right: 16.6666666667%;
  }

  .remote-content .col-md-pull-3 {
    right: 25%;
  }

  .remote-content .col-md-pull-4 {
    right: 33.3333333333%;
  }

  .remote-content .col-md-pull-5 {
    right: 41.6666666667%;
  }

  .remote-content .col-md-pull-6 {
    right: 50%;
  }

  .remote-content .col-md-pull-7 {
    right: 58.3333333333%;
  }

  .remote-content .col-md-pull-8 {
    right: 66.6666666667%;
  }

  .remote-content .col-md-pull-9 {
    right: 75%;
  }

  .remote-content .col-md-pull-10 {
    right: 83.3333333333%;
  }

  .remote-content .col-md-pull-11 {
    right: 91.6666666667%;
  }

  .remote-content .col-md-pull-12 {
    right: 100%;
  }

  .remote-content .col-md-push-0 {
    left: auto;
  }

  .remote-content .col-md-push-1 {
    left: 8.3333333333%;
  }

  .remote-content .col-md-push-2 {
    left: 16.6666666667%;
  }

  .remote-content .col-md-push-3 {
    left: 25%;
  }

  .remote-content .col-md-push-4 {
    left: 33.3333333333%;
  }

  .remote-content .col-md-push-5 {
    left: 41.6666666667%;
  }

  .remote-content .col-md-push-6 {
    left: 50%;
  }

  .remote-content .col-md-push-7 {
    left: 58.3333333333%;
  }

  .remote-content .col-md-push-8 {
    left: 66.6666666667%;
  }

  .remote-content .col-md-push-9 {
    left: 75%;
  }

  .remote-content .col-md-push-10 {
    left: 83.3333333333%;
  }

  .remote-content .col-md-push-11 {
    left: 91.6666666667%;
  }

  .remote-content .col-md-push-12 {
    left: 100%;
  }

  .remote-content .col-md-offset-0 {
    margin-left: 0%;
  }

  .remote-content .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }

  .remote-content .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }

  .remote-content .col-md-offset-3 {
    margin-left: 25%;
  }

  .remote-content .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }

  .remote-content .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }

  .remote-content .col-md-offset-6 {
    margin-left: 50%;
  }

  .remote-content .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }

  .remote-content .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }

  .remote-content .col-md-offset-9 {
    margin-left: 75%;
  }

  .remote-content .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }

  .remote-content .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }

  .remote-content .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .remote-content .col-lg-1,
  .remote-content .col-lg-2,
  .remote-content .col-lg-3,
  .remote-content .col-lg-4,
  .remote-content .col-lg-5,
  .remote-content .col-lg-6,
  .remote-content .col-lg-7,
  .remote-content .col-lg-8,
  .remote-content .col-lg-9,
  .remote-content .col-lg-10,
  .remote-content .col-lg-11,
  .remote-content .col-lg-12 {
    float: left;
  }

  .remote-content .col-lg-1 {
    width: 8.3333333333%;
  }

  .remote-content .col-lg-2 {
    width: 16.6666666667%;
  }

  .remote-content .col-lg-3 {
    width: 25%;
  }

  .remote-content .col-lg-4 {
    width: 33.3333333333%;
  }

  .remote-content .col-lg-5 {
    width: 41.6666666667%;
  }

  .remote-content .col-lg-6 {
    width: 50%;
  }

  .remote-content .col-lg-7 {
    width: 58.3333333333%;
  }

  .remote-content .col-lg-8 {
    width: 66.6666666667%;
  }

  .remote-content .col-lg-9 {
    width: 75%;
  }

  .remote-content .col-lg-10 {
    width: 83.3333333333%;
  }

  .remote-content .col-lg-11 {
    width: 91.6666666667%;
  }

  .remote-content .col-lg-12 {
    width: 100%;
  }

  .remote-content .col-lg-pull-0 {
    right: auto;
  }

  .remote-content .col-lg-pull-1 {
    right: 8.3333333333%;
  }

  .remote-content .col-lg-pull-2 {
    right: 16.6666666667%;
  }

  .remote-content .col-lg-pull-3 {
    right: 25%;
  }

  .remote-content .col-lg-pull-4 {
    right: 33.3333333333%;
  }

  .remote-content .col-lg-pull-5 {
    right: 41.6666666667%;
  }

  .remote-content .col-lg-pull-6 {
    right: 50%;
  }

  .remote-content .col-lg-pull-7 {
    right: 58.3333333333%;
  }

  .remote-content .col-lg-pull-8 {
    right: 66.6666666667%;
  }

  .remote-content .col-lg-pull-9 {
    right: 75%;
  }

  .remote-content .col-lg-pull-10 {
    right: 83.3333333333%;
  }

  .remote-content .col-lg-pull-11 {
    right: 91.6666666667%;
  }

  .remote-content .col-lg-pull-12 {
    right: 100%;
  }

  .remote-content .col-lg-push-0 {
    left: auto;
  }

  .remote-content .col-lg-push-1 {
    left: 8.3333333333%;
  }

  .remote-content .col-lg-push-2 {
    left: 16.6666666667%;
  }

  .remote-content .col-lg-push-3 {
    left: 25%;
  }

  .remote-content .col-lg-push-4 {
    left: 33.3333333333%;
  }

  .remote-content .col-lg-push-5 {
    left: 41.6666666667%;
  }

  .remote-content .col-lg-push-6 {
    left: 50%;
  }

  .remote-content .col-lg-push-7 {
    left: 58.3333333333%;
  }

  .remote-content .col-lg-push-8 {
    left: 66.6666666667%;
  }

  .remote-content .col-lg-push-9 {
    left: 75%;
  }

  .remote-content .col-lg-push-10 {
    left: 83.3333333333%;
  }

  .remote-content .col-lg-push-11 {
    left: 91.6666666667%;
  }

  .remote-content .col-lg-push-12 {
    left: 100%;
  }

  .remote-content .col-lg-offset-0 {
    margin-left: 0%;
  }

  .remote-content .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }

  .remote-content .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }

  .remote-content .col-lg-offset-3 {
    margin-left: 25%;
  }

  .remote-content .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }

  .remote-content .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }

  .remote-content .col-lg-offset-6 {
    margin-left: 50%;
  }

  .remote-content .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }

  .remote-content .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }

  .remote-content .col-lg-offset-9 {
    margin-left: 75%;
  }

  .remote-content .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }

  .remote-content .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }

  .remote-content .col-lg-offset-12 {
    margin-left: 100%;
  }
}
.remote-content .able {
  background-color: transparent;
}
.remote-content .able col[class*='col-'] {
  position: static;
  display: table-column;
  float: none;
}
.remote-content .able td[class*='col-'],
.remote-content .able th[class*='col-'] {
  position: static;
  display: table-cell;
  float: none;
}

.remote-content .aption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}

.remote-content .h {
  text-align: left;
}

.remote-content .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.remote-content .table > thead > tr > th,
.remote-content .table > thead > tr > td,
.remote-content .table > tbody > tr > th,
.remote-content .table > tbody > tr > td,
.remote-content .table > tfoot > tr > th,
.remote-content .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.remote-content .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.remote-content .table > caption + thead > tr:first-child > th,
.remote-content .table > caption + thead > tr:first-child > td,
.remote-content .table > colgroup + thead > tr:first-child > th,
.remote-content .table > colgroup + thead > tr:first-child > td,
.remote-content .table > thead:first-child > tr:first-child > th,
.remote-content .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.remote-content .table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.remote-content .table .table {
  background-color: #fff;
}

.remote-content .table-condensed > thead > tr > th,
.remote-content .table-condensed > thead > tr > td,
.remote-content .table-condensed > tbody > tr > th,
.remote-content .table-condensed > tbody > tr > td,
.remote-content .table-condensed > tfoot > tr > th,
.remote-content .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.remote-content .table-bordered {
  border: 1px solid #ddd;
}
.remote-content .table-bordered > thead > tr > th,
.remote-content .table-bordered > thead > tr > td,
.remote-content .table-bordered > tbody > tr > th,
.remote-content .table-bordered > tbody > tr > td,
.remote-content .table-bordered > tfoot > tr > th,
.remote-content .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.remote-content .table-bordered > thead > tr > th,
.remote-content .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.remote-content .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.remote-content .table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.remote-content .table > thead > tr > td.active,
.remote-content .table > thead > tr > th.active,
.remote-content .table > thead > tr.active > td,
.remote-content .table > thead > tr.active > th,
.remote-content .table > tbody > tr > td.active,
.remote-content .table > tbody > tr > th.active,
.remote-content .table > tbody > tr.active > td,
.remote-content .table > tbody > tr.active > th,
.remote-content .table > tfoot > tr > td.active,
.remote-content .table > tfoot > tr > th.active,
.remote-content .table > tfoot > tr.active > td,
.remote-content .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.remote-content .table-hover > tbody > tr > td.active:hover,
.remote-content .table-hover > tbody > tr > th.active:hover,
.remote-content .table-hover > tbody > tr.active:hover > td,
.remote-content .table-hover > tbody > tr:hover > .active,
.remote-content .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.remote-content .table > thead > tr > td.success,
.remote-content .table > thead > tr > th.success,
.remote-content .table > thead > tr.success > td,
.remote-content .table > thead > tr.success > th,
.remote-content .table > tbody > tr > td.success,
.remote-content .table > tbody > tr > th.success,
.remote-content .table > tbody > tr.success > td,
.remote-content .table > tbody > tr.success > th,
.remote-content .table > tfoot > tr > td.success,
.remote-content .table > tfoot > tr > th.success,
.remote-content .table > tfoot > tr.success > td,
.remote-content .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.remote-content .table-hover > tbody > tr > td.success:hover,
.remote-content .table-hover > tbody > tr > th.success:hover,
.remote-content .table-hover > tbody > tr.success:hover > td,
.remote-content .table-hover > tbody > tr:hover > .success,
.remote-content .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.remote-content .table > thead > tr > td.info,
.remote-content .table > thead > tr > th.info,
.remote-content .table > thead > tr.info > td,
.remote-content .table > thead > tr.info > th,
.remote-content .table > tbody > tr > td.info,
.remote-content .table > tbody > tr > th.info,
.remote-content .table > tbody > tr.info > td,
.remote-content .table > tbody > tr.info > th,
.remote-content .table > tfoot > tr > td.info,
.remote-content .table > tfoot > tr > th.info,
.remote-content .table > tfoot > tr.info > td,
.remote-content .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.remote-content .table-hover > tbody > tr > td.info:hover,
.remote-content .table-hover > tbody > tr > th.info:hover,
.remote-content .table-hover > tbody > tr.info:hover > td,
.remote-content .table-hover > tbody > tr:hover > .info,
.remote-content .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.remote-content .table > thead > tr > td.warning,
.remote-content .table > thead > tr > th.warning,
.remote-content .table > thead > tr.warning > td,
.remote-content .table > thead > tr.warning > th,
.remote-content .table > tbody > tr > td.warning,
.remote-content .table > tbody > tr > th.warning,
.remote-content .table > tbody > tr.warning > td,
.remote-content .table > tbody > tr.warning > th,
.remote-content .table > tfoot > tr > td.warning,
.remote-content .table > tfoot > tr > th.warning,
.remote-content .table > tfoot > tr.warning > td,
.remote-content .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.remote-content .table-hover > tbody > tr > td.warning:hover,
.remote-content .table-hover > tbody > tr > th.warning:hover,
.remote-content .table-hover > tbody > tr.warning:hover > td,
.remote-content .table-hover > tbody > tr:hover > .warning,
.remote-content .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.remote-content .table > thead > tr > td.danger,
.remote-content .table > thead > tr > th.danger,
.remote-content .table > thead > tr.danger > td,
.remote-content .table > thead > tr.danger > th,
.remote-content .table > tbody > tr > td.danger,
.remote-content .table > tbody > tr > th.danger,
.remote-content .table > tbody > tr.danger > td,
.remote-content .table > tbody > tr.danger > th,
.remote-content .table > tfoot > tr > td.danger,
.remote-content .table > tfoot > tr > th.danger,
.remote-content .table > tfoot > tr.danger > td,
.remote-content .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.remote-content .table-hover > tbody > tr > td.danger:hover,
.remote-content .table-hover > tbody > tr > th.danger:hover,
.remote-content .table-hover > tbody > tr.danger:hover > td,
.remote-content .table-hover > tbody > tr:hover > .danger,
.remote-content .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.remote-content .table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .remote-content .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .remote-content .table-responsive > .table {
    margin-bottom: 0;
  }
  .remote-content .table-responsive > .table > thead > tr > th,
  .remote-content .table-responsive > .table > thead > tr > td,
  .remote-content .table-responsive > .table > tbody > tr > th,
  .remote-content .table-responsive > .table > tbody > tr > td,
  .remote-content .table-responsive > .table > tfoot > tr > th,
  .remote-content .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .remote-content .table-responsive > .table-bordered {
    border: 0;
  }
  .remote-content .table-responsive > .table-bordered > thead > tr > th:first-child,
  .remote-content .table-responsive > .table-bordered > thead > tr > td:first-child,
  .remote-content .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .remote-content .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .remote-content .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .remote-content .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .remote-content .table-responsive > .table-bordered > thead > tr > th:last-child,
  .remote-content .table-responsive > .table-bordered > thead > tr > td:last-child,
  .remote-content .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .remote-content .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .remote-content .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .remote-content .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .remote-content .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .remote-content .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .remote-content .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .remote-content .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.remote-content .utput {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
}

.remote-content .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.remote-content .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.remote-content .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.remote-content .form-control:-ms-input-placeholder {
  color: #999;
}
.remote-content .form-control::-webkit-input-placeholder {
  color: #999;
}
.remote-content .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.remote-content .form-control[disabled],
.remote-content .form-control[readonly],
.remote-content .fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.remote-content .form-control[disabled],
.remote-content .fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.remote-content .extarea.form-control {
  height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .remote-content input[type='date'].form-control,
  .remote-content .nput[type='time'].form-control,
  .remote-content .nput[type='datetime-local'].form-control,
  .remote-content .nput[type='month'].form-control {
    line-height: 34px;
  }
  .remote-content input[type='date'].input-sm,
  .remote-content .input-group-sm input[type='date'],
  .remote-content input[type='time'].input-sm,
  .remote-content .input-group-sm input[type='time'],
  .remote-content input[type='datetime-local'].input-sm,
  .remote-content .input-group-sm input[type='datetime-local'],
  .remote-content input[type='month'].input-sm,
  .remote-content .input-group-sm input[type='month'] {
    line-height: 30px;
  }
  .remote-content input[type='date'].input-lg,
  .remote-content .input-group-lg input[type='date'],
  .remote-content input[type='time'].input-lg,
  .remote-content .input-group-lg input[type='time'],
  .remote-content input[type='datetime-local'].input-lg,
  .remote-content .input-group-lg input[type='datetime-local'],
  .remote-content input[type='month'].input-lg,
  .remote-content .input-group-lg input[type='month'] {
    line-height: 46px;
  }
}
.remote-content .form-group {
  margin-bottom: 15px;
}

.remote-content .radio,
.remote-content .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.remote-content .radio.disabled label,
.remote-content .fieldset[disabled] .radio label,
.remote-content .checkbox.disabled label,
.remote-content .ieldset[disabled] .checkbox label {
  cursor: not-allowed;
}
.remote-content .radio label,
.remote-content .checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.remote-content .radio input[type='radio'],
.remote-content .radio-inline input[type='radio'],
.remote-content .checkbox input[type='checkbox'],
.remote-content .checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.remote-content .radio + .radio,
.remote-content .checkbox + .checkbox {
  margin-top: -5px;
}

.remote-content .radio-inline,
.remote-content .checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}
.remote-content .radio-inline.disabled,
.remote-content .fieldset[disabled] .radio-inline,
.remote-content .checkbox-inline.disabled,
.remote-content .ieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.remote-content .radio-inline + .radio-inline,
.remote-content .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

.remote-content .form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}
.remote-content .form-control-static.input-lg,
.remote-content .form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.remote-content .input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.remote-content .elect.input-sm {
  height: 30px;
  line-height: 30px;
}

.remote-content .extarea.input-sm,
.remote-content .elect[multiple].input-sm {
  height: auto;
}

.remote-content .form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.remote-content .form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}
.remote-content .form-group-sm textarea.form-control,
.remote-content .form-group-sm select[multiple].form-control {
  height: auto;
}
.remote-content .form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.remote-content .input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.remote-content .elect.input-lg {
  height: 46px;
  line-height: 46px;
}

.remote-content .extarea.input-lg,
.remote-content .elect[multiple].input-lg {
  height: auto;
}

.remote-content .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.remote-content .form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}
.remote-content .form-group-lg textarea.form-control,
.remote-content .form-group-lg select[multiple].form-control {
  height: auto;
}
.remote-content .form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.remote-content .has-feedback {
  position: relative;
}
.remote-content .has-feedback .form-control {
  padding-right: 42.5px;
}

.remote-content .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.remote-content .input-lg + .form-control-feedback,
.remote-content .input-group-lg + .form-control-feedback,
.remote-content .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.remote-content .input-sm + .form-control-feedback,
.remote-content .input-group-sm + .form-control-feedback,
.remote-content .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.remote-content .has-success .help-block,
.remote-content .has-success .control-label,
.remote-content .has-success .radio,
.remote-content .has-success .checkbox,
.remote-content .has-success .radio-inline,
.remote-content .has-success .checkbox-inline,
.remote-content .has-success.radio label,
.remote-content .has-success.checkbox label,
.remote-content .has-success.radio-inline label,
.remote-content .has-success.checkbox-inline label {
  color: #3c763d;
}
.remote-content .has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.remote-content .has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.remote-content .has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}
.remote-content .has-success .form-control-feedback {
  color: #3c763d;
}

.remote-content .has-warning .help-block,
.remote-content .has-warning .control-label,
.remote-content .has-warning .radio,
.remote-content .has-warning .checkbox,
.remote-content .has-warning .radio-inline,
.remote-content .has-warning .checkbox-inline,
.remote-content .has-warning.radio label,
.remote-content .has-warning.checkbox label,
.remote-content .has-warning.radio-inline label,
.remote-content .has-warning.checkbox-inline label {
  color: #8a6d3b;
}
.remote-content .has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.remote-content .has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
.remote-content .has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}
.remote-content .has-warning .form-control-feedback {
  color: #8a6d3b;
}

.remote-content .has-error .help-block,
.remote-content .has-error .control-label,
.remote-content .has-error .radio,
.remote-content .has-error .checkbox,
.remote-content .has-error .radio-inline,
.remote-content .has-error .checkbox-inline,
.remote-content .has-error.radio label,
.remote-content .has-error.checkbox label,
.remote-content .has-error.radio-inline label,
.remote-content .has-error.checkbox-inline label {
  color: #a94442;
}
.remote-content .has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.remote-content .has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.remote-content .has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.remote-content .has-error .form-control-feedback {
  color: #a94442;
}

.remote-content .has-feedback label ~ .form-control-feedback {
  top: 25px;
}
.remote-content .has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.remote-content .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .remote-content .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .remote-content .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .remote-content .form-inline .form-control-static {
    display: inline-block;
  }
  .remote-content .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .remote-content .form-inline .input-group .input-group-addon,
  .remote-content .form-inline .input-group .input-group-btn,
  .remote-content .form-inline .input-group .form-control {
    width: auto;
  }
  .remote-content .form-inline .input-group > .form-control {
    width: 100%;
  }
  .remote-content .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .remote-content .form-inline .radio,
  .remote-content .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .remote-content .form-inline .radio label,
  .remote-content .form-inline .checkbox label {
    padding-left: 0;
  }
  .remote-content .form-inline .radio input[type='radio'],
  .remote-content .form-inline .checkbox input[type='checkbox'] {
    position: relative;
    margin-left: 0;
  }
  .remote-content .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.remote-content .form-horizontal .radio,
.remote-content .form-horizontal .checkbox,
.remote-content .form-horizontal .radio-inline,
.remote-content .form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
.remote-content .form-horizontal .radio,
.remote-content .form-horizontal .checkbox {
  min-height: 27px;
}
.remote-content .form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}
.remote-content .form-horizontal .form-group:before,
.remote-content .form-horizontal .form-group:after {
  display: table;
  content: ' ';
}
.remote-content .form-horizontal .form-group:after {
  clear: both;
}
@media (min-width: 768px) {
  .remote-content .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}
.remote-content .form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}
@media (min-width: 768px) {
  .remote-content .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .remote-content .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.remote-content .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.remote-content .btn:focus,
.remote-content .btn.focus,
.remote-content .btn:active:focus,
.remote-content .btn:active.focus,
.remote-content .btn.active:focus,
.remote-content .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.remote-content .btn:hover,
.remote-content .btn:focus,
.remote-content .btn.focus {
  color: #333;
  text-decoration: none;
}
.remote-content .btn:active,
.remote-content .btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.remote-content .btn.disabled,
.remote-content .btn[disabled],
.remote-content .fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.remote-content .btn.disabled,
.remote-content .fieldset[disabled] a.btn {
  pointer-events: none;
}

.remote-content .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.remote-content .btn-default:focus,
.remote-content .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.remote-content .btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.remote-content .btn-default:active,
.remote-content .btn-default.active,
.remote-content .open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}
.remote-content .btn-default:active:hover,
.remote-content .btn-default:active:focus,
.remote-content .btn-default:active.focus,
.remote-content .btn-default.active:hover,
.remote-content .btn-default.active:focus,
.remote-content .btn-default.active.focus,
.remote-content .open > .btn-default.dropdown-toggle:hover,
.remote-content .open > .btn-default.dropdown-toggle:focus,
.remote-content .open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.remote-content .btn-default.disabled:hover,
.remote-content .btn-default.disabled:focus,
.remote-content .btn-default.disabled.focus,
.remote-content .btn-default[disabled]:hover,
.remote-content .btn-default[disabled]:focus,
.remote-content .btn-default[disabled].focus,
.remote-content .fieldset[disabled] .btn-default:hover,
.remote-content .fieldset[disabled] .btn-default:focus,
.remote-content .fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.remote-content .btn-default .badge {
  color: #fff;
  background-color: #333;
}

.remote-content .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.remote-content .btn-primary:focus,
.remote-content .btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.remote-content .btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.remote-content .btn-primary:active,
.remote-content .btn-primary.active,
.remote-content .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}
.remote-content .btn-primary:active:hover,
.remote-content .btn-primary:active:focus,
.remote-content .btn-primary:active.focus,
.remote-content .btn-primary.active:hover,
.remote-content .btn-primary.active:focus,
.remote-content .btn-primary.active.focus,
.remote-content .open > .btn-primary.dropdown-toggle:hover,
.remote-content .open > .btn-primary.dropdown-toggle:focus,
.remote-content .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}
.remote-content .btn-primary.disabled:hover,
.remote-content .btn-primary.disabled:focus,
.remote-content .btn-primary.disabled.focus,
.remote-content .btn-primary[disabled]:hover,
.remote-content .btn-primary[disabled]:focus,
.remote-content .btn-primary[disabled].focus,
.remote-content .fieldset[disabled] .btn-primary:hover,
.remote-content .fieldset[disabled] .btn-primary:focus,
.remote-content .fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.remote-content .btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.remote-content .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.remote-content .btn-success:focus,
.remote-content .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}
.remote-content .btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.remote-content .btn-success:active,
.remote-content .btn-success.active,
.remote-content .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}
.remote-content .btn-success:active:hover,
.remote-content .btn-success:active:focus,
.remote-content .btn-success:active.focus,
.remote-content .btn-success.active:hover,
.remote-content .btn-success.active:focus,
.remote-content .btn-success.active.focus,
.remote-content .open > .btn-success.dropdown-toggle:hover,
.remote-content .open > .btn-success.dropdown-toggle:focus,
.remote-content .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}
.remote-content .btn-success.disabled:hover,
.remote-content .btn-success.disabled:focus,
.remote-content .btn-success.disabled.focus,
.remote-content .btn-success[disabled]:hover,
.remote-content .btn-success[disabled]:focus,
.remote-content .btn-success[disabled].focus,
.remote-content .fieldset[disabled] .btn-success:hover,
.remote-content .fieldset[disabled] .btn-success:focus,
.remote-content .fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.remote-content .btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.remote-content .btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.remote-content .btn-info:focus,
.remote-content .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.remote-content .btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.remote-content .btn-info:active,
.remote-content .btn-info.active,
.remote-content .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}
.remote-content .btn-info:active:hover,
.remote-content .btn-info:active:focus,
.remote-content .btn-info:active.focus,
.remote-content .btn-info.active:hover,
.remote-content .btn-info.active:focus,
.remote-content .btn-info.active.focus,
.remote-content .open > .btn-info.dropdown-toggle:hover,
.remote-content .open > .btn-info.dropdown-toggle:focus,
.remote-content .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.remote-content .btn-info.disabled:hover,
.remote-content .btn-info.disabled:focus,
.remote-content .btn-info.disabled.focus,
.remote-content .btn-info[disabled]:hover,
.remote-content .btn-info[disabled]:focus,
.remote-content .btn-info[disabled].focus,
.remote-content .fieldset[disabled] .btn-info:hover,
.remote-content .fieldset[disabled] .btn-info:focus,
.remote-content .fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.remote-content .btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.remote-content .btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.remote-content .btn-warning:focus,
.remote-content .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.remote-content .btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}
.remote-content .btn-warning:active,
.remote-content .btn-warning.active,
.remote-content .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}
.remote-content .btn-warning:active:hover,
.remote-content .btn-warning:active:focus,
.remote-content .btn-warning:active.focus,
.remote-content .btn-warning.active:hover,
.remote-content .btn-warning.active:focus,
.remote-content .btn-warning.active.focus,
.remote-content .open > .btn-warning.dropdown-toggle:hover,
.remote-content .open > .btn-warning.dropdown-toggle:focus,
.remote-content .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}
.remote-content .btn-warning.disabled:hover,
.remote-content .btn-warning.disabled:focus,
.remote-content .btn-warning.disabled.focus,
.remote-content .btn-warning[disabled]:hover,
.remote-content .btn-warning[disabled]:focus,
.remote-content .btn-warning[disabled].focus,
.remote-content .fieldset[disabled] .btn-warning:hover,
.remote-content .fieldset[disabled] .btn-warning:focus,
.remote-content .fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.remote-content .btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.remote-content .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.remote-content .btn-danger:focus,
.remote-content .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}
.remote-content .btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.remote-content .btn-danger:active,
.remote-content .btn-danger.active,
.remote-content .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}
.remote-content .btn-danger:active:hover,
.remote-content .btn-danger:active:focus,
.remote-content .btn-danger:active.focus,
.remote-content .btn-danger.active:hover,
.remote-content .btn-danger.active:focus,
.remote-content .btn-danger.active.focus,
.remote-content .open > .btn-danger.dropdown-toggle:hover,
.remote-content .open > .btn-danger.dropdown-toggle:focus,
.remote-content .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
.remote-content .btn-danger.disabled:hover,
.remote-content .btn-danger.disabled:focus,
.remote-content .btn-danger.disabled.focus,
.remote-content .btn-danger[disabled]:hover,
.remote-content .btn-danger[disabled]:focus,
.remote-content .btn-danger[disabled].focus,
.remote-content .fieldset[disabled] .btn-danger:hover,
.remote-content .fieldset[disabled] .btn-danger:focus,
.remote-content .fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.remote-content .btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.remote-content .btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.remote-content .btn-link,
.remote-content .btn-link:active,
.remote-content .btn-link.active,
.remote-content .btn-link[disabled],
.remote-content .fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.remote-content .btn-link,
.remote-content .btn-link:hover,
.remote-content .btn-link:focus,
.remote-content .btn-link:active {
  border-color: transparent;
}
.remote-content .btn-link:hover,
.remote-content .btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.remote-content .btn-link[disabled]:hover,
.remote-content .btn-link[disabled]:focus,
.remote-content .fieldset[disabled] .btn-link:hover,
.remote-content .fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}

.remote-content .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.remote-content .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.remote-content .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.remote-content .btn-block {
  display: block;
  width: 100%;
}

.remote-content .btn-block + .btn-block {
  margin-top: 5px;
}

.remote-content .nput[type='submit'].btn-block,
.remote-content .nput[type='reset'].btn-block,
.remote-content .nput[type='button'].btn-block {
  width: 100%;
}
