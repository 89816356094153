.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 10000
}

.loader-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.big-pulse.loader-container {
  background: #fff;
}

.loader-container.big-pulse .loader {
  width: 200px;
  height: 200px;
}

.loading-container .loader {
  width: 200px;
  height: 200px;
  color: #fff;
}